<template>
  <div>
    <h1 class="travel-tips-title text-left">{{ $t("general.travelTips") }}</h1>
    <div class="state-filters-container d-flex">
      <div class="position-relative">
        <button class="only-text" @click="setState(null)" :class="{'active-state': !filter.state}">{{ $t("general.allFemAndLifeless") }}</button>
        <div v-if="$store.state.currentUserRole == 'AUTHOR'" class="info-icon" v-tooltip.right-start="$t('info.allTravelTips')"></div>
      </div>
      <div v-for="state in states" :key="state.code" class="position-relative">
        <button  class="only-text" @click="setState(state.code)" :class="{'active-state': filter.state == state.code}">{{state.name}}</button>
        <div v-if="$store.state.currentUserRole == 'AUTHOR'" class="info-icon" v-tooltip.right-start="state.info"></div>
      </div>
    </div>
    <div class="filters-container text-left">
      <TrekioInput id="search" v-model="filter.searchString" :placeholder="$t('general.search')" searchInput/>
      <TrekioSelect multiple v-model="filter.countries" selectAllOption :isAllSelected="filter.countries.length === allCountriesInTravelTips.length" :label="$t('general.countries')"
        :placeholder="$t('general.countries')" :text="countriesSelectText"
      >
        <TrekioSelectOption v-for="country in allCountriesInTravelTips" :key="country.code" :value="country.code">{{ country[countryLocalization] }}</TrekioSelectOption>
      </TrekioSelect>

      <TrekioSelect v-model="filter.theme" :label="$t('general.theme')" :text="filter.theme ? themes.find(value => value.code === filter.theme).name : $t('general.allFemAndLifeless')"
        :placeholder="$t('general.theme')"
      >
        <TrekioSelectOption :value="null">{{ $t("general.allFemAndLifeless") }}</TrekioSelectOption>
        <TrekioSelectOption v-for="theme in themes" :key="theme.code" :value="theme.code">{{ theme.name }}</TrekioSelectOption>
      </TrekioSelect>

      <TrekioSelect v-model="filter.difficulty" :label="$t('general.difficulty')" :text="filter.difficulty ? difficulties.find(value => value.code === filter.difficulty).name : $t('general.allFemAndLifeless')"
        :placeholder="$t('general.difficulty')"
      >
        <TrekioSelectOption :value="null">{{ $t("general.allFemAndLifeless") }}</TrekioSelectOption>
        <TrekioSelectOption v-for="difficulty in difficulties" :key="difficulty.code" :value="difficulty.code">{{ difficulty.name }}</TrekioSelectOption>
      </TrekioSelect>

      <TrekioSelect v-if="this.$store.state.currentUserRole != 'AUTHOR'" :label="$t('general.tags')" v-model="filter.tagIds" selectAllOption :isAllSelected="filter.tagIds.length == filteredTags.length" multiple
          :text="filter.tagIds.length ? tags.filter(tag => filter.tagIds.includes(tag.id)).map(tag => tag.localizations[0].title).join(', ') : $t('general.allFemAndLifeless')"
      >
          <TrekioSelectOption v-for="tag in filteredTags" :key="tag.id" :value="tag.id">{{ tag.localizations[0].title }}</TrekioSelectOption>
      </TrekioSelect>

      <TrekioSelect v-if="$store.state.currentUserRole != 'AUTHOR'" v-model="filter.authorId" :label="$t('inputLabels.author')"
        :text="filter.authorId ? authorsList.find(author => author.id == filter.authorId) && authorsList.find(author => author.id == filter.authorId).userName : $t('general.allMascLiving')"
        :placeholder="$t('inputLabels.author')"
      >
        <TrekioSelectOption :value="null">{{ $t('general.allMascLiving') }}</TrekioSelectOption>
        <TrekioSelectOption v-for="author in authorsList" :key="author.id" :value="author.id">{{ author.userName }}</TrekioSelectOption>
      </TrekioSelect>

    </div>
    <TrekioSwitch class="mb-4" v-if="$store.state.currentUserRole != 'AUTHOR'" v-model="showOldTips" :constantText="$t('admin.tripsWithoutPictureAre') + ' ' + (showOldTips ? $t('admin.visible') : $t('admin.hidden'))"/>
    <p class="text-left" v-if="filteredTravelTips && filteredTravelTips.length === 0 && !isLoading">{{ $t("filter.noResults") }}</p>
    <TravelTipList v-if="filteredTravelTips && !hasFilterChanged" :travel-tips="filteredTravelTips" :showOldTips="showOldTips"/>
    <div v-if="isLoading || showMoreEnabled" class="page-loading-container">
      <div v-if="isLoading" class="loader-1 center paging-loading"><span></span></div>
      <TrekioButton v-if="showMoreEnabled && !isLoading" secondary @click="showMore">{{ $t("general.loadMore") }}</TrekioButton>
    </div>
    <div class="add-tip-button-container">
        <router-link v-if="$route.path == '/admin/travelTips' && travelTips" to='/admin/manageTravelTip' class="add-trip-anchor-tag" style="position: absolute; right: 80px">
          <div class="plus position-fixed" v-tooltip.top-end="isMaxAmountOfTravelTipsInProgressReached ? this.$t('info.maxAmountOfTravelTipsInProgressReached') : null"
            :class="{disabled: isMaxAmountOfTravelTipsInProgressReached}"
            style="bottom: 60px">

          </div>
        </router-link>
    </div>
  </div>
</template>

<script>
import TravelTipList from "@/components/admin/TravelTipList";
import {FILTERED_TRAVEL_TIPS, TRAVEL_TIPS_FOR_COUNTRIES_AUTHORS, TRAVEL_TIPS_BY_USER_ID_FOR_COUNTRIES} from "@/api/graphql/query/TravelTipQuery";
import { assignTravelTipLocalizationProperties } from "../../utils/LocalizationDataConverter";
import sharedUtils from '@/utils/sharedUtils.js'
import { TAGS } from "../../api/graphql/query/TagQuery";

const SINGLE_PAGE_SIZE = 20

export default {
  name: "TravelTipListPage",
  components: {TravelTipList},

  data: function () {
    return {
      showOldTips: true,
      travelTips: [],
      filteredTravelTips: [],
      countries: [],
      cbAllCountriesChecked: false,
      filter: {
        searchString: null,
        countries: [],
        state: null,
        theme: null,
        difficulty: null,
        tagIds: [],
        authorId: this.$store.state.currentUserRole === "AUTHOR" ? this.$store.state.currentUserId : null
      },
      pageSize: {
        page: 0,
        size: SINGLE_PAGE_SIZE,
        sortAttribute: "LENGTH_OF_STAY",
        sortDirection: "ASC"
      },
      showMoreEnabled: true,
      hasFilterChanged: true,
      userTravelTips: [],
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.getLoggingOut === false) this.$store.commit('setTravelTipsFilter', this.filter)
    next()
  },

  mounted: function () {
    if (this.$store.getters.getTravelTipsFilter != null) this.filter = this.$store.getters.getTravelTipsFilter
  },

  computed: {
    isMaxAmountOfTravelTipsInProgressReached() {
      return this.$store.state.currentUserRole === 'AUTHOR' && this.inProgressTravelTipCount >= 10
    },
    difficulties() {
      return [
        {code: 'LOW', name: this.$t("general.low")},
        {code: 'MEDIUM', name: this.$t("general.medium")},
        {code: 'HIGH', name: this.$t("general.high")}
      ]
    },
    themes() {
      return [
          {code: 'FOOD', name: this.$t("general.food")},
          {code: 'TOURISM', name: this.$t("general.nature")},
          {code: 'SPORT', name: this.$t("general.adventure")},
          {code: 'CULTURE', name: this.$t("general.culture")}
        ]
    },
    states() {
      return [
        {code: 'IN_PROGRESS', name: this.$t("admin.inProgress"), info: this.$t("info.travelTipsInProgress")},
        {code: 'TO_REWORK', name: this.$t("admin.toRework"), info: this.$t("info.travelTipsToRework")},
        {code: 'TO_CONTROL', name: this.$t("admin.toControl"), info: this.$t("info.travelTipsToControl")},
        {code: 'TO_APPROVE', name: this.$t("admin.toApprove"), info: this.$t("info.travelTipsToApprove")},
        {code: 'PUBLISHED', name: this.$t("admin.published"), info: this.$t("info.travelTipsPublished")},
        {code: 'NOT_PUBLISHED', name: this.$t("admin.notPublished"), info: this.$t("info.travelTipsNotPublished")},
        {code: 'DECLINED', name: this.$t("admin.declined"), info: this.$t("info.travelTipsDeclined")},
      ]
    },
    filteredTags() {
      if (this.tags) {
        return this.tags.filter(tag => tag.localizations[0].title != '')
      }
      return []
    },
    isLoading() {
      return this.$apollo.queries.filteredTravelTips.loading
    },
    inProgressTravelTipCount() {
      if (!this.travelTips) return
      const inProgressTips = this.travelTips.filter(tip => tip.state == 'IN_PROGRESS')
      return inProgressTips.length
    },
    authorsList: function () {
      if (!this.travelTips) return null

      const authors = [];
      this.travelTips.forEach((travelTip) => {
        if (travelTip.author) {
          if (!authors.some(author => author.id == travelTip.author.id)) {
            authors.push(travelTip.author)
          }
        }
      });

      return [...new Set(authors)];
    },
    allCountriesInTravelTips() {
      if (!this.travelTips) return [];
      let allCountries = [];
      this.travelTips.forEach(travelTip => {
        travelTip.itineraryDays.forEach(itineraryDay => {
          itineraryDay.itineraryItems.forEach(itineraryItem => {
            itineraryItem.countries.forEach(country => {
              if (!allCountries.some(c => c.code === country.code)) {
                allCountries.push(country)
              }
            })
          })
        })
      });

      return allCountries.sort((a, b) => {
        // Move selected countries to top
        if (this.filter.countries.includes(a.code) || this.filter.countries.includes(b.code)) {
          if (this.filter.countries.includes(a.code) && this.filter.countries.includes(b.code)) {
            return a[this.countryLocalization].localeCompare(b[this.countryLocalization], this.$store.state.preferredLanguage)
          }
          else if (this.filter.countries.includes(a.code) && !this.filter.countries.includes(b.code)) {
            return -1
          }
          return 1
        }
        return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
      });
    },
    countryLocalization() {
      return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
    },
    countriesSelectText() {
      if (this.filter.countries.length === this.allCountriesInTravelTips.length) {
        return this.$t("general.allFemAndLifeless")
      }
      if (this.filter.countries.length > 0) {
        const countriesNames = []
        this.allCountriesInTravelTips.forEach(country => {
          if (this.filter.countries.includes(country.code)) {
            countriesNames.push(country[this.countryLocalization])
          }
        })
        return countriesNames.join(", ")
      }
      return this.$t("general.countries")
    }
  },

  methods: {
    showMore () {
      this.pageSize.page++

      this.$apollo.queries.filteredTravelTips.fetchMore({
        variables: {
          filter: this.filter,
          pageSize: this.pageSize
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult.filteredTravelTips.length < SINGLE_PAGE_SIZE) {
            this.showMoreEnabled = false
          }

          return {
            filteredTravelTips: [
              ...previousResult.filteredTravelTips,
              ...fetchMoreResult.filteredTravelTips,
            ],
          }
        },
      })
    },
    setState(chosenState) {
      this.filter.state = chosenState;
    }
  },

  watch: {
    filter: {
      handler(newValue, oldValue) {
        this.pageSize.page = 0
        this.hasFilterChanged = true
        this.showMoreEnabled = true
      },
      deep: true
    },
    '$store.state.currentUserRole'(nV) {
      this.showOldTips = nV == 'AUTHOR' ? true : false
    },
  },

  apollo: {
    tags: {
      query: TAGS,
      skip() {
        return this.$store.state.currentUserRole == 'AUTHOR'
      }
    },

    travelTips: {
      query() {
        return this.$store.state.currentUserRole === 'AUTHOR' ? TRAVEL_TIPS_BY_USER_ID_FOR_COUNTRIES : TRAVEL_TIPS_FOR_COUNTRIES_AUTHORS
      },
      variables() {
        if (this.$store.state.currentUserRole === 'AUTHOR') return {userId: this.$store.state.currentUserId}
      },
      update(data) {
        return data.travelTips.map(travelTip => assignTravelTipLocalizationProperties(travelTip))
      },
      skip() {
        return !this.$store.state.currentUserRole
      },
      fetchPolicy: 'cache-and-network',
    },

    filteredTravelTips: {
      query: FILTERED_TRAVEL_TIPS,
      variables() {
        return {
          filter: {
            searchString: this.filter.searchString,
            countries: this.filter.countries,
            state: this.filter.state,
            theme: this.filter.theme,
            difficulty: this.filter.difficulty,
            tagIds: this.filter.tagIds,
            authorId: this.$store.state.currentUserRole === "AUTHOR" ? this.$store.state.currentUserId : this.filter.authorId
          },
          pageSize: {
            page: 0,
            size: SINGLE_PAGE_SIZE,
            sortAttribute: "LENGTH_OF_STAY",
            sortDirection: "ASC"
          },
        }
      },
      update(data) {
        return data.filteredTravelTips.map(travelTip => assignTravelTipLocalizationProperties(travelTip))
      },
      result({data}) {
        if (data.filteredTravelTips.length < SINGLE_PAGE_SIZE) {
          this.showMoreEnabled = false
        }
        this.hasFilterChanged = false
      },
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.$store.state.currentUserRole
      }
    }
  }
}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.page-loading-container {
  max-width: 1810px;
  margin-top: 30px
}

.state-filters-container {
  border-bottom: 1px solid $tr-light-gray;
  margin-bottom: 40px;
  flex-wrap: wrap;

  .info-icon {
    position: absolute;
    top: 13px;
    right: 0;
  }

  button {
    position: relative;
    padding: 10px 20px 15px;
    color: $tr-black;

    &:focus-visible {
      outline: 2px solid $tr-green !important;
    }

    &.active-state {
      color: $tr-green
    }

    @media (min-width: 1425px) {
      color: $tr-gray;
      &.active-state {
        color: $tr-black;
  
        &::after {
            position: absolute;
            left: 0;
            bottom: 5px;
            content: "";
            width: 100%;
            height: 3px;
            background-color: $tr-green;
  
            @media (min-width: 1400px) {
              bottom: -2px;
            }
        }
      }
    }
    


  }

  .active-state {
    color: $tr-green;
  }
}

.only-text {
  background: none !important;
  border: none !important;
}

.filters-container {
  padding-bottom: 50px;
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, auto));
}

.green-switch{
  width: 60px !important;
  max-width: 60px !important;
  height: 32px !important;
  max-height: 32px !important;
  background-color: $tr-light-gray !important;
  border-radius: 40px !important;
  position: relative !important;
  background-image: none !important;
  appearance: none !important;
  border-color: transparent !important;
  cursor: pointer;

  &::after{
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 40px;
    background-color: $tr-gray;
    left: 0px;
    top: 0px;
    transition: 0.2s ease-in-out;
    transition-property: left, background-color;
  }

  &:checked{
    &::after{
      background-color: $tr-green;
      left: 30px;
    }
  }

  &+label{
    padding-left: 10px;
    transform: translateY(4px);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $tr-black;
  }
}

.add-tip-button-container {
  position: relative;
  max-width: 1810px;

  .plus.disabled {
    background-color: #9D9D9D;
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }
  }
}

</style>